import { Injectable } from '@angular/core';
import { ITableState } from '@app/data/models/ITableState';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { HttpCriteria } from '@paella-front/ngx-super-http-client';

const HOUR_TRACKING_VIEW_MODE = 'hour-tracking-view-mode';

@Injectable({
  providedIn: 'root',
})
export class TableStateService {
  private tableState: ITableState = null;

  getTableState(route: string): ITableState {
    return this.tableState && this.tableState.route === route.split('/')[1]
      ? this.tableState
      : null;
  }

  setTableState(
    route: string,
    pagination: any,
    criteria: HttpCriteria,
    selectDropdown?: { entity: string; value: any },
    dateRange?: { entity: string; value: { from: NgbDate; to: NgbDate } }
  ): void {
    try {
      const splitURL = route.split('/')[1];
      this.tableState = {
        route: splitURL,
        pagination: pagination,
        criteria: criteria,
        filters: this.setFilters(criteria),
        selectDropdownCollection: this.setSelectDropdown(selectDropdown),
        dateRange:
          dateRange && dateRange.value
            ? dateRange
            : this.tableState?.dateRange
              ? this.tableState?.dateRange
              : null,
      };
    } catch (error) {
      console.error(error);
    }
  }

  clear(): void {
    this.tableState = null;
  }

  setHourTrackingViewMode(value: string): void {
    localStorage.setItem(HOUR_TRACKING_VIEW_MODE, value);
  }

  getHourTrackingViewMode(): string | null {
    return localStorage.getItem(HOUR_TRACKING_VIEW_MODE) || 'card';
  }

  private setSelectDropdown(selectDropdown?: {
    entity: string;
    value: any;
  }): Array<{ entity: string; value: any }> {
    const collection = this.tableState?.selectDropdownCollection
      ? this.tableState.selectDropdownCollection
      : [];
    if (!selectDropdown) {
      return collection;
    }
    if (collection.length === 0 && selectDropdown.value) {
      return [selectDropdown];
    }
    const index = collection.findIndex(
      (item: { entity: string; value: any }) => item.entity === selectDropdown.entity
    );
    if (index !== -1) {
      collection.splice(index, 1);
      if (selectDropdown.value) {
        collection.push(selectDropdown);
      }
    } else {
      if (selectDropdown.value) {
        collection.push(selectDropdown);
      }
    }
    return collection;
  }

  private setFilters(criteria: HttpCriteria): any[] {
    const filters: any = [];
    criteria._filters.forEach((filter: any) => {
      if (filter.list) {
        filter.list.forEach((subfilter: any) => {
          filters.push({
            key: subfilter.field,
            value: subfilter.value.toString().replaceAll('%', ''),
          });
        });
      } else {
        filters.push({
          key: filter.field,
          value: filter?.value ? filter.value.toString().replaceAll('%', '') : null,
        });
      }
    });
    return filters;
  }
}
